<template>
    <div class="home">
      <Header/>
      <div class="content"> 
        <div class="title">{{$t('m.gglb')}}</div>   
        <div class="card" >
      <div class="titles">{{$t('m.zytz')}}</div>
      <div class="time">2025-03-13</div>
      <div class="box">
        {{$t('m.ggxx1')}}
      </div> 
      <div class="end">{{$t('m.syyz')}}</div>      
     </div>  
        <div class="card" >
      <div class="titles">{{$t('m.zytz')}}</div>
      <div class="time">2025-02-03</div>
      <div class="box">
        {{$t('m.ggxx')}}
      </div> 
      <div class="end">{{$t('m.syyz')}}</div>      
     </div> 
     <div class="card" >
      <div class="titles">{{$t('m.zytz')}}</div>
      <div class="time">2025-01-06</div>
      <div class="box">
        {{$t('m.wgxlk')}}
      </div>  
      <div class="box">
        {{$t('m.jrq')}}
      </div>  
      <div class="end">{{$t('m.syyz')}}</div>
      
     </div>
     <div class="card" >
      <div class="titles">{{$t('m.ggtt')}}</div>
      <div class="time">2024-12-30</div>
      <div class="box">
        {{$t('m.ggnr')}}
      </div>  
     
     </div>
  
    </div>
    <Bottom/>

    
</div>
</template>
<script>
import Header from '../components/header.vue';
import Bottom from '../components/bottom.vue';
export default {
  name: "myContracts",
  components: {Header,Bottom},
  data() {
    return {
        current:0,
        loading:false,
        dcshow:true,
        list:[],
        lists:[],
        lqNum:0,
        lqType:0,
        lqshow:false,
        lqloading:false,
        countNum:0,
        nodePool:0,
        rankPool:0,
        isNode:false,
        userWallet:"",
        userInfo:""
    }
  },
  filters: {
    yingcang(val) {
      if (val) {
        let a = val.replace(/(\w{8})\w+(\w{8})/, "$1...$2");
        return a;
      } else {
        return "";
      }
    },
  },
  created(){
  },
  methods:{
  }
}

</script>
<style lang="scss" scoped>
@import "../../src/assets/scss/_handle.scss";

$t-mf: "PingFang";
$t-f: "DINAlternate-Bold";

.home {
  background:  #000000 url("../assets/img/pbg.png")  no-repeat;
  // background: #161628;
  background-size:contain;
  background-position: center bottom;
  overflow-x: hidden;
  width: 750px;
  margin: 0 auto;
  min-height: 100vh;
    .content{
        margin-top: 188px;
        min-height: 100vh;
        .title{
      font-size: 38px;
      font-weight: bold;
      color: #ffffff;
      margin: 72px auto;
      border-radius: 36px 0 36px 0;
      width: 360px;
      padding: 22px;
      position: relative;
    }
    .title::after{
      content: "";
      position: absolute;
      height: 4px;
      width: 100px;
      border-radius: 16px;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      background: linear-gradient(200deg, #12c2fc 0%, #3ae2f8 31%, #14a5d0 49%, #441dda 79%, #0b1042 100%);
    }
    
        .card {
      // width: 694px;
      // background:#ffffff url("../assets/img/bgs.png") no-repeat ;
      background: #00000070;
         background-size: cover;
    background-position: left top;
          align-items: center;
          border: 1px solid #fc00ff;
      border-radius: 20px;
      padding: 30px 40px;
      margin: 20px 30px 20px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .titles{
        font-size: 28px;
          font-weight: 600;
          color: #ffffff;
          width: 100%;
          border-bottom: 1px solid rgb(53, 53, 53);
          text-align: left;
          position: relative;
          padding-left: 42px;
          margin-bottom: 10px;
          box-sizing: border-box;
          padding-bottom: 10px;
      }
      .titles::after{
        position: absolute;
      height: 32px;
      width: 32px;
      top: calc(50% - 5px);
      left: 0px;
      content: "";
      transform: translateY(-50%);
      background: #fc00ff;
      background: url("../assets/img/logo.png") no-repeat ;
      background-size: 100% 100%;
      }
      .time{
        font-size: 22px;
        color: #3ae2f8;
        text-align: left;
        padding-bottom: 10px;
        // padding-left: 20px;
        width: 100%;
      }
      
      .box{
        width: 100%;
        // padding-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: #ffffff;
        text-align: left;
        font-size: 26px;
        margin-bottom: 10px;
      }
      .end{
        text-align: right;
        width: 100%;
        font-size: 22px;
        color: #aab3b4;
      }
    }
}
      
}

  </style>
